import React from "react";
import {Box, Grid, List, ListItem, styled, Typography} from "@mui/material";

export default function QuizQuestion({questionId, risposte, corretta, answer, sendAnswer, poll}) {
    const RispostaStyled = styled(Box)({
        border: '2px solid #F1F1F1',
        borderRadius: '0.7rem',
        background: 'rgba(241,241,241,0.2)',
        cursor: 'pointer',
        aspectRatio: '1/1',
        padding: '0.7rem',
    })

    const computeBkg = (r) => {
        if (!poll) {
            if (answer !== '') {
                if (corretta === r) {
                    return '#F1F1F1'
                } else if (answer === r && answer !== corretta) {
                    return '#DB0A16'
                }
            }
        } else {
            if (answer === r) {
                return '#F1F1F1'
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <Grid item xs={6}
                  sx={{
                      scale: answer ? answer === r ? '1.05' : '0.9' : '1',
                      opacity: answer ? answer === r ? '1' : '0.5' : '1',
                  }}>
                <RispostaStyled key={i} style={{
                    width: '100%',
                    background: computeBkg(r),
                    border: computeBkg(r) ? '2px solid ' + computeBkg(r) : '2px solid rgba(241,241,241,0.5)',
                    color: 'white',
                }} onClick={() => rispondi(r)}>
                    <img
                        src={`https://storage.googleapis.com/2ndstage-public/NnqDXznykLmPllgXMiFc/photo-contest/${questionId}/${r}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '0.5rem',
                            marginBottom: '-0.7rem'
                        }}/>
                    <Typography sx={{pt:2}} color={answer ? answer === r ? 'black' : "white" : "white"}>
                        {i + 1}
                    </Typography>
                </RispostaStyled>
            </Grid>
        ))
    )

    const rispondi = (r) => {
        if (poll) {
            sendAnswer(r)
        } else {
            if (answer === '') {

                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Grid container spacing={1} justifyContent={'center'}>
                <Risposte/>
            </Grid>
        </List>
    )
}
