import React, {useEffect, useState} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {ClickToQuestion} from "./ClickToQuestion";
import SiNoQuestion from "./SiNoQuestion";
import {SendPhotoQuestion} from "./SendPhotoQuestion";
import SentimentQuestion from "./SentimentQuestion";
import QuizQuestion from "./QuizQuestion";
import WordcloudQuestion from "./WordcloudQuestion";
import {QnaQuestion} from "./QnaQuestion";
import ToolpressQuestion from "./ToolpressQuestion";
import {useParams} from "react-router-dom";
import {auth, firestore} from "../../firebase/clientApp";
import axios from "axios";
import {API_URL} from "../../config";
import {AltroQuestion} from "./AltroQuestion";
import {AudioQuestion} from "./AudioQuestion";

export const QuestionSwitch = ({elementPlaying, theme}) => {
    const {id} = useParams()

    const [answer,setAnswer] = useState('')
    const [answered, setAnswered] = useState(false)
    const [sending, setSending] =useState(false)
    const [success, setSuccess] =useState(false)
    const user = auth.currentUser

    useEffect(()=> {
        findAnswered()
        findAnswer()
    },[elementPlaying])

    function findAnswered(){
        var docRef = firestore.collection("users/plesh/events/"+id+"/queue/"+elementPlaying.id+"/answers").doc(user.uid);
        docRef.get().then((doc) => {
            if (doc.exists) {
                setAnswered(true)
            } else {
                setAnswered(false)
            }

        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function findAnswer(){
        var docRef = firestore.collection("users/plesh/events/"+id+"/queue/"+elementPlaying.id+"/answers").doc(user.uid);
        docRef.get().then((doc) => {
            if (doc.exists) {
                setAnswer(doc.data().answer)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function sendAnswer(answer) {
        let data = {
            answer: answer,
            user:user.uid
        }
        setSuccess(false)
        setSending(true)
        axios.post(`${API_URL}/answer/${id}/${elementPlaying.id}`, data)
            .then(response => {
                setSuccess(true)
                setAnswered(true)
                setSending(false)
                setAnswer(answer)
            })
            .catch( error => {
                setSending(false)
            })
    }

    return(
        <Box>
            <Backdrop
                sx={{ color: '#f1ebe9', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sending}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {(() => {
                switch (elementPlaying.tipo) {
                    case 'clickTo':
                        return <ClickToQuestion element={elementPlaying} sendAnswer={sendAnswer}/>
                    case 'siNo':
                        return <SiNoQuestion tipo={elementPlaying.tipo} sendAnswer={sendAnswer} answer={answer} sending={sending}/>
                    case 'sendPhoto':
                        return <SendPhotoQuestion elementId={elementPlaying.id} sendAnswer={sendAnswer} answer={answer} sending={sending}/>
                    case 'sentiment':
                        return <SentimentQuestion tipo={elementPlaying.tipo} sendAnswer={sendAnswer} answer={answer} sending={sending} theme={theme}/>
                    case 'quiz':
                        return <QuizQuestion risposte={elementPlaying.risposte} corretta={elementPlaying.corretta} sendAnswer={sendAnswer} answer={answer} sending={sending}/>
                    case 'poll':
                        return <QuizQuestion questionId={elementPlaying.id} risposte={elementPlaying.risposte} sendAnswer={sendAnswer} answer={answer} sending={sending} poll={true}/>
                    case 'wordcloud':
                        return <WordcloudQuestion elementId={elementPlaying.id} tipo={elementPlaying.tipo} sendAnswer={sendAnswer} answered={answered} answer={answer} sending={sending} success={success}/>
                    case 'qna':
                        return <QnaQuestion elementId={elementPlaying.id} tipo={elementPlaying.tipo} sendAnswer={sendAnswer} answered={answered} answer={answer} sending={sending} success={success}/>
                    case 'toolpress':
                        return <ToolpressQuestion elementPlaying={elementPlaying}/>
                    case 'altro':
                        return <AltroQuestion elementPlaying={elementPlaying}/>
                    case 'audio':
                        return <AudioQuestion elementId={elementPlaying.id} sendAnswer={sendAnswer} answer={answer} sending={sending}/>
                    default:
                        return null
                }
            })()}
        </Box>
    )
}